.card-title {
	height: 50px;
	overflow: hidden;
}

Detalle {
	position: fixed;
	bottom: 0;
	left: 0;
}

.card-text-cut {
	height: 100px;
	overflow: hidden;
}

.favorite-btn {
	width: 40px;
	height: 40px;
	background-color: #f2f2f2;
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 10px;
	right: 10px;
	border: none;
}

svg-icon {
	width: 50px;
	height: 50px;
}

Footer {
	background-color: #131930;
	box-shadow: inset 0 0 70px rgb(0 0 0 / 30%), 0 0 20px rgb(0 0 0 / 50%);
}

@media screen and (max-width: 358px) {
	.carta {
		flex-direction: column;
		width: 100%;
		margin: 0;
	}
}
